<template>
  <div class="auth_reg_container">
    <div class="auth_reg_block">
      <div class="auth_reg_block_header">
        <div
          class="header_auth"
          :class="[active_form === 'auth' ? 'header_active' : 'header_btn']"
          @click="selectForm('auth')"
        >
          Авторизация
        </div>
        <!--        <div-->
        <!--          class="header_reg"-->
        <!--          :class="[active_form === 'reg' ? 'header_active' : 'header_btn']"-->
        <!--          @click="selectForm('reg')"-->
        <!--        >-->
        <!--          Регистрация-->
        <!--        </div>-->
      </div>
      <div class="auth_reg_block_body">
        <div class="auth_reg_block_body_logo"></div>
        <div class="auth_reg_block_body_title">сервис заказа полиграфии</div>
        <div v-show="active_form === 'auth'" class="auth_reg_block_form">
          <el-form
            :rules="rulesAuth"
            ref="AuthForm"
            :label-position="labelPosition"
            :model="formLabelAuth"
            size="medium"
            @submit.native.prevent="onEnter"
            :disabled="send_data"
          >
            <el-form-item label="Введите Ваш логин:" prop="login">
              <el-input
                v-model="formLabelAuth.login"
                placeholder="Login"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="Введите Ваш пароль:" prop="password">
              <el-input
                type="password"
                v-model="formLabelAuth.password"
                placeholder="************"
                show-password
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <div class="auth_reg_block_btn">
              <el-button
                native-type="submit"
                size="medium"
                class="btn_132"
                @click.prevent="submitForm(formName)"
                :loading="send_data"
                plain
                >{{ btnTitle }}</el-button
              >
            </div>
          </el-form>
        </div>
        <div v-show="active_form === 'reg'" class="auth_reg_block_form">
          <el-form
            :rules="rulesReg"
            ref="RegForm"
            :label-position="labelPosition"
            :model="formLabelReg"
            size="medium"
            @submit.native.prevent="onEnter"
            :disabled="send_data"
          >
            <el-form-item label="Введите Ваше ФИО:" prop="fio">
              <el-input
                v-model="formLabelReg.fio"
                placeholder="Иванов Иван Иванович"
              ></el-input>
            </el-form-item>
            <el-form-item label="Введите Ваш телефон:" prop="phone">
              <el-input
                type="phone"
                v-model="formLabelReg.phone"
                v-mask="'+7 ~## ### ## ##'"
                placeholder="+7 000 000 00 00"
              ></el-input>
            </el-form-item>
            <div class="auth_reg_block_btn">
              <el-button
                native-type="submit"
                size="medium"
                class="btn_132"
                @click.prevent="submitForm(formName)"
                :loading="send_data"
                plain
                >{{ btnTitle }}</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    let validateFIO = (rule, value, callback) => {
      if (this.regFio(value)) {
        callback();
      } else {
        callback(new Error("Формат ФИО: Иванов Иван Иванович, Иванов Иван"));
      }
    };
    return {
      active_form: "auth",
      labelPosition: "top",
      formLabelAuth: {
        login: "",
        password: "",
      },
      formLabelReg: {
        fio: "",
        phone: "+7 ",
      },
      rulesAuth: {
        login: [
          {
            required: true,
            message: "Пожалуйста введите логин",
            trigger: "change",
          },
          {
            max: 30,
            message: "Длинна логина до 30 символов",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Пожалуйста введите пароль",
            trigger: "change",
          },
          {
            min: 6,
            max: 30,
            message: "Длинна пароля от 6 до 30 символов",
            trigger: "blur",
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
      rulesReg: {
        fio: [
          {
            required: true,
            message: "Пожалуйста введите ФИО",
            trigger: "change",
          },
          {
            validator: validateFIO,
            trigger: "blur",
            transform(value) {
              return value.trim();
            },
          },
        ],
        phone: [
          {
            required: true,
            message: "Пожалуйста введите телефон",
            trigger: "change",
          },
          {
            min: 11,
            message: "Минимум 11 цифр",
            trigger: "blur",
            transform(value) {
              return value.trim().replace(/\+7/, "7").replace(/\s/g, "");
            },
          },
        ],
      },
      send_data: false,
    };
  },
  computed: {
    formName() {
      if (this.active_form === "auth") return "AuthForm";
      else return "RegForm";
    },
    btnTitle() {
      if (this.active_form === "auth") return "Войти";
      else return "Отправить";
    },
  },
  methods: {
    selectForm(form) {
      this.resetForm(this.formName);
      this.active_form = form;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login();
        } else {
          return false;
        }
      });
    },
    onEnter() {
      this.submitForm(this.formName);
    },
    regFio(value) {
      var regExp =
        /^([А-ЯA-Z]|[А-ЯA-Z][\x27а-яa-z]{1,}|[А-ЯA-Z][\x27а-яa-z]{1,}-([А-ЯA-Z][\x27а-яa-z]{1,}|(оглы)|(кызы)))\040[А-ЯA-Z][\x27а-яa-z]{1,}(\040[А-ЯA-Z][\x27а-яa-z]{1,})?$/;
      return regExp.test(value);
    },
    login() {
      this.send_data = true;
      let request = {
        login: this.formLabelAuth.login,
        password: this.formLabelAuth.password,
      };
      this.$store
        .dispatch("Login/LOGIN", request)
        .then((response) => {
          if (response.data.status && response.data.status === 2000) {
            this.resetForm(this.formName);
            if (this.$route.query.redirect) {
              let url = this.$route.query.redirect ?? "";
              this.$router.push({ path: url });
            } else {
              this.$router.push({ path: "/" });
            }
          }
        })
        .finally(() => {
          this.send_data = false;
        });
    },
  },
};
</script>

<style lang="sass">
@import "src/assets/sass/auth_reg"
</style>
